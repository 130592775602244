/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

 /* For Ionic7-calendar */
 @import 'swiper/css'; 

.action-custom-class {
    --color: blue;
    --button-color-hover: yellow;
    --background: var(--ion-color-secondary-tint);
  }

.nova-localizacao-page-modal {
    //  --height: 60%;
    // --min-height: 60%;
    --border-radius: 10px;
    padding: 25px;
}

.calendar-modal {
  --height: 90%;
  --border-radius: 10px;
  --border-color: --var(--ion-color-primary-tint);
  --border-style: solid;
  --border-width: 1px;
  background: rgba(222, 225, 228, 0.9);
  padding: 10px; 
}

